<template>
  <div class="home">
    <HeadTop />
    <div class="select">
      <div class="selectLeft">
        <span :class="selectCurrent==index?'spanActive':'spanText'" v-for="(item, index) in selectSearch"
          :key="item.key" @click="searchSelect(index,item)">{{item.name}}</span>
      </div>
      <!-- <div class="border"> </div>
            <div class="selectRight">
                    <a-checkbox-group @change="onChange">
                        <a-checkbox value="0">
                            免费
                        </a-checkbox>
                        <a-checkbox value="1">
                            付费
                        </a-checkbox> 
                    </a-checkbox-group>
            </div> -->
    </div>
    <div class="empty" v-if='list.length==0'>
      <a-empty></a-empty>
    </div>
    <ul class="activeList" v-else>
      <li class="activeLi" @click="watchNowFun(item)" v-for="(item, index) in list" :key="index">
        <div class="imgDetail">
          <img class="imgTop" :src="resBasePath+'/'+item.livePhotoCover" alt="">
        </div>
        <ul class="activeDesc">
          <li>
            <span class="title">{{item.title}}</span>
            <img v-if="item.isPayment==0" class="freeImg" width="18px" height="18px" src="../../assets/img/free.png"
              alt="">
          </li>
          <li>
            <a-tooltip placement="top" :title="item.liveTeacherInfo">
              <div class="titleName">{{item.liveTeacherName}} {{item.liveTeacherInfo}}</div>
            </a-tooltip>
          </li>
          <li>
            <a-icon type="aliwangwang" />
            <span class="watchNum">{{item.playCount}}人已经观看</span>
            <span class="watchNow">立即观看</span>
          </li>
        </ul>
      </li>
    </ul>
    <div style="text-align:center;margin:15px 0px;">
      <a-config-provider>
        <a-pagination show-quick-jumper :default-current="1" :page-size="pageSize" @change="handleListChange"
          show-less-items :total=total />
      </a-config-provider>
    </div>
  </div>
</template>

<script>
import HeadTop from '@/components/HeadTop.vue'
import axios from 'axios'
export default {
    name: 'allActive',
    data(){
        return {
            pageSize: 20,
            pageNum: 1,
            list: [],
            selectCurrent:0,
            selectSearch: [
                {name: '全部', key: ''},
                {name: '直播中', key: '1'},
                // {name: '未开始', key: '2'},
                {name: '已结束', key: '3'},
            ],
            isPayment: '',
            liveStatus: '',
            total: 0,
            resBasePath: this.$resBasePath
        }
    },
    mounted(){
      this.getList()
    },
    methods:{
      getList (){
        let params = {
          isPayment:this.isPayment,
          liveStatus: this.liveStatus,
          pageSize: this.pageSize,
          pageNum: this.pageNum
        }
        let _this = this
        axios({
          url: _this.$baseServer + 'ebook/officialLive/getOfficialAllLivePage',
          method: 'POST',
          data: params
        }).then(res => {
          if(res && res.data.success && res.data.resultCode===0){
            _this.list = res.data.resultData.list
            _this.total = res.data.resultData.total
          }
        })
      },
      searchSelect (index, item) {
        this.selectCurrent = index
        this.liveStatus = item.key
        this.getList()
      },
      handleListChange (current, pageSize) {
        this.pageNum = current
        this.pageSize = pageSize
        this.getList()
      },
      onChange (){

      },
      watchNowFun(item){
        axios({
          url: this.$baseServer + 'ebook/officialLive/savePlayCount',
          method: 'POST',
          data: {
            id: item.id
          }
        }).then(res => {

        })
        sessionStorage.setItem('videoDetail',JSON.stringify(item))
        this.$router.push({name: 'activeVideo'})
      }
    },
    components:{
        HeadTop
    }
}
</script>

<style scoped lang="less">
    ul,li{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .home{
        width: 1140px;
        margin: auto;
        .select{
            height: 30px;
            width: 100%;
            display: flex;
            margin-top: 10px;
            .selectLeft{
                display: flex;
                cursor: pointer;
                width: 400px;
                .spanText{
                    display: inline-block;
                    width: 120px;
                    height: 32px;
                    line-height:32px;
                    color: #000;
                    font-size: 14px;
                    text-align: center;
                }
            }
            .border{
                width: 60px;
                height: 20px;
                border-left: 1px solid #7f7f7f;
                margin-top: 5px;
            }
            .selectRight{
                margin-top: 5px;
            }
        }
        .activeList{
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
          min-height: 500px;
          .activeLi{
            width :calc(25% - 20px);
            cursor: pointer;
            height: 280px;
            margin: 10px 10px;
            background: #fff;
            .imgDetail{
              height: 160px;
              .imgTop{
                width: 100%;
                height: 100%;
                display: block;
              }
            }
            .activeDesc{
              margin-top: 10px;
              padding: 0px 10px;
              .title{
                display: inline-block;
                width: 180px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space:nowrap;
                height: 26px;
                font-size: 16px;
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 500;
                color: #000000;
                line-height: 26px;
              }
              .freeImg{
                width: 25px;
                height: 20px;
                margin-top: 2px;
                float: right;
              }
              .titleName{
                height: 35px;
                font-size: 12px;
                font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                font-weight: 400;
                color: #000000;
                line-height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space:nowrap;
            }
            .watchNum{
              height: 18px;
              font-size: 12px;
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              font-weight: 400;
              color: #7F7F7F;
              line-height: 18px;
              margin-left: 5px;
            }
            .watchNow{
              display: inline-block;
              width: 60px;
              height: 24px;
              background: linear-gradient(122deg, #FF8D46 0%, #FA5542 100%);
              border-radius: 4px;
              font-weight: 400;
              color: #FFFFFF;
              font-size: 12px;
              line-height: 24px;
              text-align: center;
              float: right;
              cursor: pointer;
            }
          }
        }
      }
      .empty{
        height: 500px;
        padding-top: 50px;
      }
    }
.spanActive{
    width: 120px;
    height: 32px;
    line-height: 32px;
    background: #DB5647;
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}
</style>